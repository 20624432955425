.poker-table {
    height: 280px;
    text-align: center;
    width: 550px;
    background: radial-gradient(circle, #005f1a 0%, #003d13 100%),
        url('../../assets/noise-texture.avif');
    border-radius: 200px;
    border: 10px solid black;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 1);
    transform: scale(1.4);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-size: cover;
    z-index: 0;
}

.poker-table::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    background: url('../../assets/noise-texture.avif');
    opacity: 0.12;
    background-size: cover;
    border-radius: 200px;
    z-index: -1;
    pointer-events: none;
}

.pot-size {
    font-weight: bold;
    color: rgb(252, 252, 129);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    margin-top: -200px;
}

/* for card gap and position*/
.cards-container {
    display: flex;
    gap: 10px;
    margin-left: -2px;
    position: relative;
    z-index: 2;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.players {
    position: fixed;
    bottom: -55%;
    left: 37.2%;
    transform: translateX(+10px);
}
