.raise-container {
    position: absolute;
    bottom: -65%;
    /* right: 20%; */
    background-color: #121947;
    border-radius: 15px;
    padding: 20px;
    width: 500px;
    display: flex;
    flex-direction: column;
    border: 3px solid #798ec3;
    gap: 15px;
    color: white;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #e41e3f;
    border: none;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.slider-adjustment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.slider-section {
    flex-grow: 2;
}

.raise-slider {
    width: 100%;
    appearance: none;
    height: 8px;
    background: #798ec3;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}

.raise-slider::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background: #c1f784;
    border-radius: 50%;
    cursor: pointer;
}

/* Manual Adjustment */

.manual-adjust .current-bet {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0a0e29;
    color: white;
    font-weight: normal;
    font-size: 16px;

    height: 100%;
    padding: 5px 10px;
    border: 2px solid #798ec3;
    border-radius: 5px;
    text-align: center;
    width: 50px;
}

/* Confirm Button */
.confirm-button {
    background-color: #c1f784;
    color: #0a0e29;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    height: 100%;
}

.confirm-button:hover {
    background-color: #e3ffc4;
    color: #0a0e29;
}

/* Buttons Below Slider */
.fraction-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Matches slider width */
    margin-top: 10px;
}

.fraction-buttons button {
    flex: 1; /* Ensures equal width */
    background-color: #0a0e29;
    color: lightgray;
    border: none;
    border-radius: 10px;
    border: 2px solid #798ec3;
    padding: 5px 0;
    cursor: pointer;
    font-size: 12px;
    margin: 0 2px; /* Add spacing between buttons */
}

.fraction-buttons button:first-child {
    margin-left: 0; /* Remove extra space on the left */
}

.fraction-buttons button:last-child {
    margin-right: 0; /* Remove extra space on the right */
}

.fraction-buttons button:hover {
    background-color: lightblue;
}

.chips-icon {
    width: 17px;
    height: 17px;
}
