.chip {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 0.1px solid black;
    z-index: 5;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}
.chip-container {
    position: relative;
}

.chip-image {
    width: 30px;
    height: 30px;
    z-index: 5;
}
.chip-value {
    font: bold;
    font-size: 12px;
    color: white;
    text-shadow: 1px 1px 2px black;
    position: absolute;
    z-index: 2;
}

.chip:hover {
    transform: scale(1.1) translate(+20px, -10px);
    transition: transform 0.3s ease, margin 0.3s ease;
    transition-delay: 0.1s;
}

.chip:hover::before {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
    background: transparent;
}
