.player {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
}

/* Cards on top */
.player-cards {
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
}

/* Player info container */
.player-info-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    padding: 2px 15px;
    height: 40px;
    width: 100px;
    padding-left: 43px;
    margin-left: -21.5px;
}

.player-winning-chance {
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 30px;
    top: -5%;
    transform: translateY(-50%);
    font-size: 10px;
    color: #ffffff;
    margin: 0;
    padding: 2px 4px;
    border-radius: 5px;
    min-width: 40px; 
}

/* Profile picture */
.player-image {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid white;
    overflow: hidden;
    /* background-color: #fff;  */
}

.player-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

/* Player details (name and balance) */
.player-details {
    display: flex;
    flex-direction: column;
}

.player-name {
    font-size: 15px;
    color: white;
    font-weight: normal;
}

.player-balance {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: rgb(226, 226, 226);
    margin-top: 2px;
}

.chips-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

/* Player action */
.player-action {
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    color: rgb(252, 252, 129);
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
}

/* Player chips positioning remains the same */
.player-chips {
    position: relative;
}

/* Current player highlight */
.player.current .player-image {
    border: 2px solid #8fa3d8;
    animation: highlight 0.8s ease-in-out infinite;
    /* box-shadow: 0 0 10px #8fa3d8, 0 0 20px #8fa3d8, 0 0 30px #8fa3d8; */
}

@keyframes highlight {
    0% {
        box-shadow: 0 0 5px rgba(143, 163, 216, 1),
            0 0 10px rgba(143, 163, 216, 0.8), 0 0 15px rgba(143, 163, 216, 0.6);
        border-color: rgba(143, 163, 216, 1);
    }
    50% {
        box-shadow: 0 0 5px rgba(191, 211, 255, 1),
            0 0 10px rgba(191, 211, 255, 0.8), 0 0 15px rgba(191, 211, 255, 0.6);
        border-color: rgba(191, 211, 255, 1);
    }
    100% {
        box-shadow: 0 0 5px rgba(143, 163, 216, 1),
            0 0 10px rgba(143, 163, 216, 0.8), 0 0 15px rgba(143, 163, 216, 0.6);
        border-color: rgba(143, 163, 216, 1);
    }
}

/* Positioning for different player positions */
.player.bottom {
    bottom: 60px;
    transform: translateY(-5%);
}

.player.bottom-left {
    left: -260px;
    bottom: 60px;
    transform: translateY(-5%);
}

.player.bottom-right {
    left: 260px;
    bottom: 60px;
    transform: translateY(-5%);
}

.player.left {
    left: -310px;
    bottom: 250px;
    transform: translateY(-5%);
}

.player.right {
    left: 310px;
    bottom: 250px;
    transform: translateY(-5%);
}

/* Positioning for player chips */
.player-chips.bottom {
    bottom: 95px;
    right: -60px;
}

.player-chips.bottom-left {
    bottom: 90px;
    left: 50%;
}

.player-chips.bottom-right {
    bottom: 90px;
    right: 70%;
}

.player-chips.left {
    left: 60%;
}

.player-chips.right {
    right: 90%;
}

.player.folded .player-info-container {
    filter: grayscale(100%);
    opacity: 0.6;
}

.player.folded .player-action {
    filter: grayscale(100%);
    opacity: 0.6;
}

.player.folded .player-cards {
    filter: grayscale(100%);
    opacity: 0.6;
}

.blind-token {
    font-weight: 500;
    color: black;
    left: 25%;
    padding: 5px;
    border-radius: 50%;
    width: 10px;
    font-size: 11px;
    height: 10px;
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blind-token.big {
    background-color: rgb(252, 252, 129);
}

.blind-token.small {
    background-color: #5c92e4;
}
/* .token-bottom {
    bottom: 100px;
    left: -20%;
}
.token-bottom-left {
    bottom: 95px;
    left: 120%;
}

.token-bottom-right {
    bottom: 100px;
    right: 90%;
}
.token-left {
    left: 120px;
    bottom: 20%;
}
.token-right {
    right: 100px;
    bottom: 20%;
} */
