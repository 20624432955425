html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

body,
button {
    font-family: 'Montserrat', sans-serif;
}

.App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-color: black;
}

.start-screen {
    position: flex;
    border-radius: 5vh;
    text-align: center;
    width: 68vh;
    overflow: hidden;
}

.welcome-message {
    display: flex;
    font-size: 3vw;
    font-weight: bold;
    color: white;
    position: fixed;
    top: 5vh;
    left: 5vw;
}

.practice-mode {
    position: absolute;
    top: 70px; 
    right: 10px;
    z-index: 30;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.toggle-wrapper {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: white;  
    border-radius: 10px;      
    border: 2px solid #798ec3;  
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  /* shadow for a subtle effect */
  }

.practice-mode-label {
    font-size: 16px;
    color: black; 
    font-weight: 500;
    margin-right: 10px;
}

.toggle-container {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    z-index: 30; 
    cursor: pointer;
    background-color: #ccc;
    border-radius: 34px;
    transition: background-color 0.4s;
}

.toggle-container.active {
    background-color: #798ec3;  /* Green when active */
}

.slider {
    position: absolute;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: transform 0.4s;
}

.slider.active {
    transform: translateX(26px);  
}

.play-button {
    padding: 10px 20px;
    background-color: #798ec3;
    color: white;
    border: 2px solid white;
    border-radius: 15px;
    cursor: pointer;
    padding: 15px 30px;
    margin-top: 35vh;
    transform: scale(1.5);
}

.play-button:hover {
    background-color: #303a6c;
}


.player-registration {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0; 
    padding: 5px;
    margin-top: 10vh;
}

.player-registration .name-input {
    padding: 10px;
    font-size: 16px;
    margin: 10px 0;
    width: 200px;
    text-align: center;
    border: 2px solid #ddd;
    border-radius: 4px;
    outline: none;
}

.player-registration .name-input:focus {
    border-color: #4caf50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.player-selection {
    position: absolute;
    top: calc(55vh + 80px);
    left: 50%;
    transform: translate(-50%);
    display: flex; 
    justify-content: center; 
    align-items: center;
    gap: 10px; 
    margin: 0 auto; 
    z-index: 10; /* Ensure the buttons stay on top */
    width: auto; /* Ensures it doesn't break layout on different screen sizes */
    padding: 0 20px; /* Optional padding for some spacing */
}


.player-selection-button {
    padding: 10px 20px;
    background-color: white; 
    color: black;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px; 
    border: 2px solid #798ec3;
    font-size: 11pt;
    font-weight: 500;
    margin: 5px; /* Space between buttons */
    transition: background-color 0.3s, transform 0.2s;
}


.player-selection-button:hover {
    background-color: #303a6c; 
}

/* Selected state for player selection buttons */
.player-selection-button.selected {
    background-color: #798ec3; 
    color: white;
    transform: scale(1.1); 
}


.action-button-container {
    position: fixed;
    bottom: -70%;
    display: flex;
    flex-direction: row; /* Arrange buttons horizontally */
    gap: 10px; /* Space between buttons */
    z-index: 100;
    width: auto; /* Adjust as needed */
    height: 60px; /* Set a consistent height */
    justify-content: center; /* Center buttons within the container */
    align-items: center; /* Center buttons vertically */
}

.action-button {
    padding: 10px 20px;
    background-color: #0a0e29; /* Default button color */
    color: lightgray;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    border: 2px solid #798ec3;
    font-size: 11pt;
    font-weight: 300;
}

.action-button:hover {
    background-color: #303a6c; /* Hover state color */
}

.raise-options-popup {
    color: white;
    position: absolute;
    bottom: -80%;
    right: 15%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 10px;
    z-index: 500;
    width: 80px;
    height: auto;
}

.win-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(247, 255, 246);
    border: 2px solid green;
    align-items: center;
    border-radius: 10px;
    padding: 50px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.win-popup h2 {
    margin: 0 0 20px;
}

.win-popup button {
    padding: 10px 15px;
    background-color: grey;
    position: relative;
    left: 38%;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.win-popup button:hover {
    background-color: darkgreen;
}

.rules-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.rules-popup button {
    padding: 10px 20px;
    background-color: white; /* Same as action and selection buttons */
    color: black;
    border: none;
    border-radius: 15px; /* Same as action and selection buttons */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto; /* Auto width based on content */
    font-size: 16pt;
    font-weight: 600;
    border: 2px solid #798ec3; /* Same border as player selection buttons */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transition */
}

.rules-popup button:hover {
    background-color: #303a6c; /* Same hover color as action and selection buttons */
}


.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 100;
    max-width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popup button {
    padding: 15px 20px;
    background-color: #0a0e29;
    color: lightgray;
    border: 2px solid #798ec3;
    border-radius: 15px;
    cursor: pointer;
}

.popup button:hover {
    background-color: #303a6c;
}

.popup ul,
.popup ol {
    text-align: left;
    list-style-position: inside;
    padding-left: 20px;
    margin: 0;
    width: 100%;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .transition-container {
    position: absolute;
    top: 3%;
    left: -20%;
    width: 140%;
    height: 7%;
    z-index: 2;
  }

  .transition-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }
  
  .transition-video-alt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }